require.config({
    paths: {
        'asyncjs'         : 'vendor/async/async',
        'jquery'          : 'vendor/jquery/jquery',
        'foundation'      : 'vendor/foundation/foundation',
        'jquery.cookie'   : 'vendor/jquery.cookie/jquery.cookie.min',
        'fastclick'       : 'vendor/fastclick/fastclick',
        'modernizr'       : 'vendor/modernizr/modernizr',
        'placeholder'     : 'vendor/jquery-placeholder/jquery.placeholder.min',
        'jquery.inputmask': 'vendor/jquery.inputmask/jquery.inputmask.bundle', // jshint ignore:line
        'handlebars'      : 'vendor/handlebars/handlebars.runtime',
        'ymaps'           : 'http://api-maps.yandex.ru/2.1/?lang=ru-RU'
    },
    shim: {
        'foundation': {
            deps   : ['jquery', 'modernizr'],
            exports: 'Foundation'
        },
        'jquery.cookie': {
            deps: ['jquery']
        },
        'fastclick': {
            exports: 'FastClick'
        },
        'modernizr': {
            exports: 'Modernizr'
        },
        'placeholder': {
            exports: 'Placeholders'
        },
        'jquery.inputmask': {
            deps: ['jquery']
        },
        'handlebars': {
            exports: 'Handlebars'
        },
        'ymaps': {
            exports: 'ymaps'
        }
    },
    deps: ['app']
});
